@import '../../styles/variables.scss';

.main-carousel-home {
    .container-carousel-home {
        position: relative;
        overflow: hidden;
        .carousel-home {
            div {
                .active {
                    opacity: 1;
                    transition: opacity 0.5s linear;
                }
                .img-carousel-home {
                    width: 100vw;
                }
                .container-info-banner0 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 100vw;
                    bottom: 6rem;
                }
                .container-info-banner1 {
                    @extend .container-info-banner0;
                    bottom: 17em;
                    left: 1.5rem;
                    justify-content: flex-start;
                }
                .container-info-banner2 {
                    @extend .container-info-banner0;
                    bottom: 17rem;
                    justify-content: end;
                    right: 1.5rem;
                }
                .disable {
                    display: none;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    color: transparent;
                }
            }
        }
    }
}

@media screen and (min-width: $desktop) {
    .main-carousel-home {
        .container-carousel-home {
            .carousel-home {
                div {
                    .container-info-banner0 {
                        bottom: 1.5rem;
                    }
                    .container-info-banner1 {
                        bottom: 8em;
                        left: 4.5rem;
                    }
                    .container-info-banner2 {
                        bottom: 10rem;
                        right: 4.5rem;
                    }
                }
            }
        }
    }
}