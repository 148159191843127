@import '../../styles/variables.scss';

.container-check {
    margin-top: 1.5rem;
    display: flex;
    align-items: flex-start;
    .logo-check {
        width: 1.5rem;
        margin-right: 0.5rem;
    }
    .p-check {
        @extend .text;
    }
}

@media  screen and (min-width: $tablet) {
    .container-check {
        .p-check {
            white-space: normal;
        }
    }
}
@media  screen and (min-width: $desktop) {
    .container-check {
        width: 22rem;
        .p-check {
            white-space: normal;
        }
    }
}