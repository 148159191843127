$red: #ED1B24;
$transparent-red: #ed1b26db;
$transparent-red-hover-nav-bar: #ed1b2633;
$dark-red: #620B0F;
$dark-red2: #990F15;
$white: #FCFCFC;
$dark-white: #D9D9D9;
$transparent-white: rgba(252, 252, 252, 0.30);
$black: #202020;
$gray: #797979;
$gray-opinion: #D9D9D9;
$transparent-gray: rgba(230, 230, 230, 0.85);
$dark-gray: #323232;
$navBar: rgb(50 50 50);
$transparent: #fcfcfc00;

//MEDIASQUERIES
$desktop: 1000px;
$tablet: 500px;

$margin-main-mobile: 0 1.5rem;
$margin-main-desktop: 0 4.5rem;

.arrow-carousel {
    padding: 0 0 0 20px;
    position: relative;
    font-size: 40px;
    font-weight: 600;
    z-index: 1;
    user-select: none;
    color: $gray;
}

.txtVideoInteligente {
    white-space: pre-line;
    font-size: 32px;
    line-height: 50px;
    letter-spacing: 0.4px;
    color: $red;
    font-weight: 800;
}


//VARIABLES DE TEXTOS CHICOS Y GRANDES CON MEDIASQUERIES
.text {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 30px;
}
.text-20 {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 30px;
}
.title-underline-32 {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0.4px;
    line-height: 50px;
}
.title-home-44 {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -4.5%;
}

@media screen and (min-width: $desktop) {
    .text {
        font-size: 20px;
        letter-spacing: 0.4px;
        line-height: 36px;
    }
    .text-20 {
        font-size: 26px;
        letter-spacing: 0px;
        line-height: 39px;
    }
    .title-underline-32 {
        font-size: 40px;
        font-weight: 800;
        letter-spacing: 0.4px;
        line-height: 60px;
    }
    .title-home-44 {
        font-size: 60px;
        line-height: 80px;
        letter-spacing: -4%;
    }
    .arrow-carousel {
        cursor: pointer;
    }
}
