@import '../../styles/variables.scss';

.main-carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .leftArrow, .rightArrow {
        @extend .arrow-carousel;
    }
    .leftArrow {
        padding: 0 20px 0 0;
    }
    .container-carousel {
        position: relative;
        overflow: hidden;
        .carousel {
            height: 19rem;
            div {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .active {
                    opacity: 1;
                    display: block;
                    transition: opacity 0.5s linear;
                }
                .title-carousel {
                    white-space: pre-line;
                    width: 9rem;
                    text-align: center;
                    @extend .text-20;
                    font-weight: 700;
                }
                .text-carousel {
                    text-align: center;
                    font-weight: 500;
                    padding: 1.5rem 0;
                    @extend .text;
                    line-height: normal;
                }
                .disable {
                    display: none;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    color: transparent;
                }
            }
        }
    }
}