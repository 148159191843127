@import '../../styles/variables.scss';

.main-cv {
    .container-cv {
        border: 4px solid $dark-red;
        border-radius: 12px;
        margin: $margin-main-mobile;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        .container-title-and-img {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .title-cv {
                white-space: pre-line;
                color: $red;
                font-weight: 700;
                @extend .text;
                font-size: 24px;
            }
            .logo-cv {
                width: 3.75rem;
            }
        }
        .container-content-cv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70%;
            .p-cv {
                @extend .text;
                font-weight: 500;
            }
            .container-email {
                margin: 0.5rem 0;
                .email-cv {
                    cursor: pointer;
                    @extend .text;
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (min-width: $desktop){
    .main-cv {
        display: flex;
        justify-content: center;
        .container-cv {
            width: 32rem;
            margin:  $margin-main-desktop;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
}