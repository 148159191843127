@import '../../styles/variables.scss';


.container-title {
    padding: 1.5rem 0;
    .title-red {
        position: relative;
        @extend .title-underline-32;
        color: $dark-red;
        text-align: left;
        padding: 0.5rem 0;
    }
    .title-red::before {
        content: '';
        position: absolute;
        top: 100%;
        right: -23px;
        height: 3px;
        width: 80%;
        max-width: 18.7rem;
        background-color: $dark-red;
    }
    
    .title-white {
        @extend .title-red;
        color: $white;
    }
    .title-white::before {
        content: '';
        position: absolute;
        top: 100%;
        right: -23px;
        height: 3px;
        width: 80%;
        max-width: 18.7rem;
        background-color: $white;
    }
}

@media screen and (min-width: 415px) {
    .container-title {
        width: fit-content;
        min-width: 22.5rem;
        .title-red::before {
            content: '';
            position: absolute;
            top: 100%;
            right: -23px;
            height: 3px;
            width: 80%;
            max-width: 18.7rem;
            background-color: $dark-red;
        }
        .title-white::before {
            content: '';
            position: absolute;
            top: 100%;
            right: -23px;
            height: 3px;
            width: 80%;
            max-width: 18.7rem;
            background-color: $white;
        }
    }
}