@import './styles/variables.scss';
// ---------IMPORT FONT--------------
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

//config values
main {
  background-color: $white;
}


//reset all values to default
* {
  color: $black;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}