@import '../../styles/variables.scss';


.div-container-div-NS {
    margin: 1.5rem 0;
    display: flex;
    justify-content: center;
    .container-btn-NS {
        cursor: pointer;
        border-radius: 12px;
        width: 12rem;
        background-color: $red;
        transition: 0.3s;
        padding: 0.7rem 1.3rem;
        .text-btn-NS {
            text-align: center;
            color: $white;
            font-weight: 700;
            @extend .text-20;
            line-height: 20px;
        }
    }:hover {
        transition: 0.3s;
        background-color: $dark-red;
    }
}
.div-container-div-home {
    display: flex;
    .container-btn-home {
        cursor: pointer;
        border-radius: 12px;
        padding: 0.6rem 1.2rem;
        transition: 0.3s;
        background-color: $transparent-red;
        .text-btn-home {
            text-align: center;
            color: $white;
            font-weight: 700;
            @extend .text;
        }
    }:hover {
        transition: 0.3s;
        background-color: $white;
        .text-btn-home {
            color: $black;
        }
    }
}
.div-container-div-Cotizar {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
    .container-btn-Cotizar {
        text-align: center;
        transition: 0.3s;
        cursor: pointer;
        border-radius: 12px;
        padding: 0.6rem 0;
        width: 100%;
        background-color: $transparent-white ;
        .text-btn-Cotizar {
            text-align: center;
            color: $white;
            font-weight: 700;
            @extend .text;
        }
    }:hover {
        transition: 0.3s;
        background-color: $white;
        .text-btn-Cotizar {
            color: $black;
        }
    }
}