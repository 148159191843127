@import '../../styles/variables.scss';


.footer {
    padding-top: 2rem;
    background-color: $dark-white;
    .container-logo-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;
    }
    .container-contact-footer {
        margin: $margin-main-mobile;
        height: 8.2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .div-emergencias {
            height: 4rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .emergencias {
                text-align: center;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.4px;
                color: $red;
                font-weight: 700;
            }
            .telefono {
                font-size: 24px;
            }
        }
        .social-media-logos {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            .img-social-media {
                width: 2rem;
            }
        }
    }
    .container-contact-data {
        margin: $margin-main-mobile;
        margin-bottom: 2rem;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 2rem;
        .contact-data {
            width: 78vw;
            .p-contact-data {
                font-size: 14px;
                line-height: 24px;
            }
            .p-bold-contact-data{
                font-weight: 700;
                @extend .p-contact-data;
            }
        }
    }
    .container-pol-priv {
        margin: $margin-main-mobile;
        .p-politica-de-privacidad {
            @extend .p-contact-data;
            font-weight: 700;
            text-decoration: underline;
        }
    }
    .container-credits-footer {
        background-color: $dark-gray;
        margin-top: 1.5rem;
        .p-credits {
            padding: 0.5rem 0;
            font-style: italic;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: $red;
            .a-credits {
                color: $red;
                text-decoration: none;
            }
        }
    }
}

@media screen and (min-width: 1300px){
    .footer {
        position: relative;
        padding-top: 6.9rem;
        .container-logo-footer {
            position: absolute;
            left: 4.5rem;
            top: 2.5rem;
            margin: $margin-main-desktop;
            margin-bottom: 1rem;
        }
        .container-contact-footer {
            margin: $margin-main-desktop;
            width: 16rem;
            height: 8rem;
            .social-media-logos {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .img-social-media {
                    width: 2rem;
                }
            }
        }
        .container-contact-data {
            margin: 0;
            position: absolute;
            right: 4.5rem;
            top: 6.9rem;
            height: 9.5rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 3rem;
            .contact-data {
                max-width: 17rem;
                display: flex;
                flex-direction: column;
            }
        }
        .container-pol-priv {
            width: 16rem;
            text-align: center;
            margin: $margin-main-desktop;
            margin-top: 1.5rem;
        }
    }
}