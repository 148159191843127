@import '../../styles/variables.scss';


@keyframes fade-up {
    0% {
        transform: translateY(120px);
        opacity: 0;
    }
    50% {
        opacity: 100;
    }
    100% {
        transform: translateY(0px);
    }
}

.container-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    .card {
        overflow: hidden;
        width: 24rem;
        height: 24rem;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 12px;
        .img-card {
            width: 8.2rem;
        }
        .title-card {
            white-space: pre-line;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }
        .text-card {
            padding: 1.5rem 1rem;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
        }
        .active-btn-card {
            animation: fade-up 0.5s ease-out;
            transition: 0.5s;
            display: flex;
            opacity: 100%;
            align-items: center;
            justify-content: center;
            height: 120px;
            background-color: $red;
            position: absolute;
            bottom: 0px;
            width: 100%;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .p-btn-card-cotizar {
                cursor: pointer;
                font-size: 20px;
                letter-spacing: 0.4px;
                line-height: 20px;
                font-weight: 700;
                color: $white;
                padding: 2.8rem 6.5rem;
            }
        }
        .disable-btn-card {
            display: none;
            opacity: 0%;
        }
    }
    .shadow {
        transition: 0.2s;
        box-shadow: 0px 0px 15px 0px #2020207d;
    }
}