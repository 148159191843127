@import '../../styles/variables.scss';


.imgVideoInteligente-container {
    margin-top: 2.5rem;
    position: relative;
    .container-txt-1 {
        z-index: 1;
        top: -1.5rem;
        left: 1.5rem;
        position: absolute;
        background-color: $transparent-gray;
        width: 70%;
        max-width: 20rem;
        padding: 10px;
        border-radius: 20px;
        .txt {
            @extend .txtVideoInteligente;
        }
    }
    .container-txt-2 {
        z-index: 1;
        top: 40rem;
        right: 1.5rem;
        position: absolute;
        background-color: $transparent-gray;
        width: 70%;
        max-width: 20rem;
        padding: 10px;
        border-radius: 20px;
        .txt {
            text-align: right;
            @extend .txtVideoInteligente;
        }
    }
    .container-txt-3 {
        z-index: 1;
        bottom: 30rem;
        left: 1.5rem;
        position: absolute;
        background-color: $transparent-gray;
        width: 70%;
        max-width: 22rem;
        padding: 10px;
        border-radius: 20px;
        .txt {
            @extend .txtVideoInteligente;
        }
    }
    .bg-polygon {
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 0;
        height: 146rem;
        position: relative;
        background-size: initial;
        background-repeat: repeat-x;
        background-image: url('../../img/videoverificacion/bg-polygon.png');
        .bg-1 {
            top: 9.7rem;
            position: absolute;
            width: 21rem;
            height: 56.25rem;
            background-size: contain;
            background-repeat: no-repeat;
            right: 0;
            background-image: url('../../img/videoverificacion/bg-1.png');
        }
        .bg-2 {
            top: 58rem;
            background-position: center;
            position: absolute;
            width: 100%;
            height: 15rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../img/videoverificacion/bg-2.png');
        }
        .bg-3 {
            bottom: 16.8rem;
            position: absolute;
            width: 19rem;
            height: 63rem;
            background-size: contain;
            background-repeat: no-repeat;
            left: 0;
            background-image: url('../../img/videoverificacion/bg-3.png');
        }
        .bg-4 {
            bottom: 0;
            position: absolute;
            width: 22.5rem;
            height: 33.25rem;
            background-size: contain;
            background-repeat: no-repeat;
            right: 0;
            background-image: url('../../img/videoverificacion/bg-4.png');
        }
    }
}

@media screen and (min-width: 700px) {
    .imgVideoInteligente-container {
        .bg-polygon {
            background-image: url('../../img/videoverificacion/bg-polygon-desktop.png');
        }
    }
}
@media screen and (min-width: 900px) {
    .imgVideoInteligente-container {
        .container-txt-1 {
            top: 2.8rem;
            left: 10rem;
        }
        .container-txt-2 {
            top: 36rem;
            right: 4.5rem;
        }
        .container-txt-3 {
            left: 10rem;
            bottom: 3rem;
        }
        .bg-polygon {
            height: 86rem;
            background-image: url('../../img/videoverificacion/bg-polygon-desktop.png');
            .bg-1 {
                top: -7rem;
                width: 42.6rem;
                right: 0;
                background-image: url('../../img/videoverificacion/bg-1-tablet.png');
            }
            .bg-2 {
                top: 27rem;
                width: 19.6rem;
                left: 20rem;
            }
            .bg-3 {
                top: 35.3rem;
                width: 44rem;
                height: 41.2rem;
                background-image: url('../../img/videoverificacion/bg-3-tablet.png');
            }
        }
    }
}
@media screen and (min-width: 1300px) {
    .imgVideoInteligente-container {
        .container-txt-3 {
            left: 20rem;
            bottom: 3rem;
        }
        .bg-polygon {
            .bg-1 {
                top: -7rem;
                width: 53rem;
                right: 0;
                background-image: url('../../img/videoverificacion/bg-1-desktop.png');
            }
            .bg-2 {
                top: 27rem;
                width: 19.6rem;
                left: 30rem;
            }
            .bg-3 {
                top: 35.3rem;
                width: 74rem;
                height: 41.2rem;
                background-image: url('../../img/videoverificacion/bg-3-desktop.png');
            }
            .bg-4 {
                bottom: -13.6rem;
                width: 41.7rem;
                height: 41.7rem;
                background-image: url('../../img/videoverificacion/bg-4-desktop.png');
            }
        }
    }
}