@import '../../styles/variables.scss'; 

.container-nuestrosClientes {
    margin: $margin-main-mobile;
}

@media screen and (min-width: $desktop){
    .container-nuestrosClientes {
        margin:  $margin-main-desktop;
    }
}