@import '../../styles/variables.scss';


.img-bg {
    padding-bottom: 1rem;
    position: relative;
    background-repeat: repeat;
    background-image: url('../../img/smartPanic/hexasBg.png');
    .container-smartPanic {
        margin: $margin-main-mobile;
        .content {
            .img-app {
                width: 90vw;
                height: auto;
                object-fit: contain;
            }
            .container-check-and-subtitle {
                display: flex;
                flex-direction: column;
                .subtitle-smartPanic {
                    @extend .text;
                    font-weight: 700;
                    color: $black;
                    margin-top: 1.5rem;
                }
                .container-check-component {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
        }
    }
}
.img-bg::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to bottom, $transparent, $white);
    pointer-events: none;
}

@media screen and (min-width: $tablet){
    .img-bg {
        .container-smartPanic {
            .content {
                .img-app {
                    width: 26rem;
                    height: fit-content;
                }
            }
        }
    }
}
@media screen and (min-width: $desktop){
    .img-bg {
        background-image: url('../../img/smartPanic/hexasBg-2.png');
        .container-smartPanic {
            margin:  $margin-main-desktop;
            .content {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                .img-app {
                    width: 26rem;
                }
                .container-check-and-subtitle {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: 1.5rem;
                }
            }
        }
    }
}