@import '../../styles/variables.scss';

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    .nav {
        height: 3.5rem;
        padding: 0 1.5rem;
        display: flex;
        justify-content: space-between;
        background-color: $red;
        .btn-nav {
            background-color: transparent;
            border: none;
            .img-menu {
                width: 3rem;
            }
        }
    }
}
.ul-nav-bar {
    transition: opacity 0.3s linear;
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $navBar;
    opacity: 95%;
    list-style: none;
    .li-nav-bar {
        cursor: pointer;
        a {
            padding: 20px 0;
            display: block;
            width: 90vw;
            text-align: center;
            @extend .text;
            font-weight: 700;
            color: $white;
        }
    }
}
.nav-bar-bg {
    z-index: 2;
    top: 405px;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: absolute;
    background-color: transparent;
}

.disable {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s linear;
}

@media screen and (min-width: $desktop) {
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 4.5rem;
        background-color: $white;
        box-shadow: 0px 4px 20px 1px #00000057;
        .nav {
            padding-left: 4.5rem;
            align-items: center;
            background-color: $white;
        }
        .ul-nav-bar-desktop {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            list-style: none;
            width: 70%;
            padding-right: 4.5rem;
            .li-nav-bar-desktop {
                cursor: pointer;
                a {
                    font-weight: 700;
                    color: $dark-red;
                    padding: 1.7rem;
                }:hover {
                    background-color: $transparent-red-hover-nav-bar;
                }
            }
            .li-nav-bar-desktop-cotizar {
                cursor: pointer;
                a {
                    font-weight: 700;
                    color: $white;
                    padding: 0.7rem 1.2rem;
                    background-color: $red;
                    border-radius: 12px;
                }
            }
        }
    }
}