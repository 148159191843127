@import '../../styles/variables.scss';

.container-form {
    .from {
        .div-input {
            margin-top: 1.5rem;
            .input-from {
                width: 98%;
                font-size: 20px;
                letter-spacing: 0.4px;
                border: none;
                border-radius: 8px;
                padding: 1rem 0 1rem 1rem;
                color: $black;
            }
        }
        .div-textarea {
            margin-top: 1.5rem;
            .textarea-form {
                height: 5.5rem;
                width: 98%;
                font-size: 20px;
                letter-spacing: 0.4px;
                border: none;
                border-radius: 8px;
                padding: 1rem 0 1rem 1rem;
                color: $black;
            }
        }
        .p-message-form {
            text-align: center;
            margin-top: 1rem;
            color: $white;
            @extend .text;
            line-height: 25px;
            font-weight: 500;
        }
        .spinner-form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
            padding: 2rem;
            .txt-loader {
                padding-top: 1rem;
                font-weight: 400;
                color: $white;
            }
            .loader {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                position: relative;
                animation: rotate 1s linear infinite
            }
            .loader::before {
                content: "";
                box-sizing: border-box;
                position: absolute;
                inset: 0px;
                border-radius: 50%;
                border: 3px solid $white;
                animation: prixClipFix 2s linear infinite ;
            }
        
            @keyframes rotate {
                100%   {transform: rotate(360deg)}
            }
        
            @keyframes prixClipFix {
                0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
                25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
                50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
                75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
                100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
            }
        }
        .btn-submit {
            cursor: pointer;
            border-radius: 12px;
            padding: 0.6rem 0;
            width: 100%;
            background-color: $transparent-white ;
            text-align: center;
            color: $white;
            font-weight: 700;
            @extend .text;
            border: none;
            margin: 1.5rem 0;
            transition: 0.3s;
        }
        .btn-submit:hover {
            transition: 0.3s;
            background-color: $white;
            color: $black;
        }
    }
}