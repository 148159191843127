@import '../../styles/variables.scss';

.container-Servicios {
    margin: $margin-main-mobile;
}

@media screen and (min-width: $desktop){
    .container-Servicios {
        margin:  $margin-main-desktop;
    }
}