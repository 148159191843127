@import '../../styles/variables.scss';

.bg-cotizar {
    background: linear-gradient(to bottom left, $dark-red2 30%, $red 56%, $dark-red2 90%);
    .container-cotizar {
        padding-top: 1.5rem;
        margin: $margin-main-mobile;
        .main-container-content-cotizar {
            .container-content-cotizar {
                display: flex;
                flex-direction: column;
                .p-cotizar {
                    text-align: left;
                    display: block;
                    color: $white;
                    font-weight: 700;
                    @extend .text;
                }
            }
        }
    }
}


@media screen and (min-width: 1300px){
    .bg-cotizar {
        .container-cotizar {
            margin:  $margin-main-desktop;
            .main-container-content-cotizar {
                display: flex;
                justify-content: center;
                .container-content-cotizar {
                    width: 35rem;
                }
            }
        }
    }
}