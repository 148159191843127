@import '../../styles/variables.scss';

.container-videointeligente {
    .content-1, .content-2 {
        margin: $margin-main-mobile;
        .cont-info {
            .p-videoInteligente {
                @extend .text;
                margin-bottom: 1.5rem;
            }
        }
    }
}



@media screen and (min-width: 1300px){
    .container-videointeligente {
        .content-2 {
            display: flex;
            justify-content: space-between;
            width: fit-content;
            .cont-info {
                width: 18rem;
                margin-right: 2rem;
            }
        }
    }
}
@media screen and (min-width: $desktop){
    .container-videointeligente {
        .content-1, .content-2 {
            margin:  $margin-main-desktop;
        }
    }
}